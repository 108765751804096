import { graphql, useStaticQuery } from 'gatsby'

const useCheckoutMsg = () => {
  const blocks = []
  const data = useStaticQuery(graphql`
    query CheckoutThankYouPage {
      contentfulNewPage(title: { eq: "Ambassador Onboard" }) {
        blocks {
          ... on ContentfulImageWithOverlayText {
            id
            __typename
            fontColor
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            title
            subheading
            textBlockWidth
            buttonTitle
            buttonLink
          }
          ... on ContentfulTextBlock {
            __typename
            title
            content {
              raw
            }
            ctaButton {
              title
              slug
            }
          }
        }
      }
    }
  `)
  data.contentfulNewPage.blocks.map(item => blocks.push(item))

  return blocks
}

export default useCheckoutMsg
