import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Row } from 'react-bootstrap'
import FormError from '~/components/FormError'
import useAmbassadorTermsQuery from '~/hooks/graphql/queries/use-ambassador-terms'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { ContentfulRichTextOptions } from '~/components/ContentfulRichTextOptions'

const CheckoutTerms = ({ handleAgree, register, errors }) => {
  const ambassadorTerms = useAmbassadorTermsQuery()
  const richTextOptions = ContentfulRichTextOptions
  const [show, setShow] = useState(false)

  useEffect(() => {
    return () => {
      setShow(false)
    }
  }, [])

  const onClickAgree = () => {
    setShow(false)
    handleAgree(true)
  }
  return (
    <Row className="mb-5">
      <div className="d-flex">
        <Form.Group
          onClick={() => setShow(true)}
          className="d-inline-flex flex-row align-items-center c-pointer"
          controlId="acceptTerms"
        >
          <input
            type="checkbox"
            style={{ width: '20px', height: '20px' }}
            className="me-3 m-0"
            {...register('acceptTerms', { required: 'Agree with Terms' })}
          />
          <Form.Label className="m-0 txt-blue-light">Agree Terms and conditions</Form.Label>
        </Form.Group>
      </div>
      <FormError error={errors.acceptTerms} />

      <Modal className="font-sfpro" show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{ambassadorTerms.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderRichText(ambassadorTerms.content, richTextOptions)}</Modal.Body>
        <Modal.Footer>
          <div className="d-flex w-100 justify-content-center">
            <Button variant="primary" onClick={() => onClickAgree()}>
              Agree
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Row>
  )
}

export default CheckoutTerms
