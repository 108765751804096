import React from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { Link } from 'gatsby'
import ContenfulImageOverlayText from '~/components/ContenfulImageOverlayText'
import RichTextRenderer from '~/components/ContentfulRichTextRender'
import useCheckoutMsg from '~/hooks/graphql/queries/use-checkout-msg'

const CheckoutThankYou = ({ isEligibleAmbassador }) => {
  const blocks = useCheckoutMsg()

  const TextBlock = data => {
    const { title, content, ctaButton } = { ...data.data }
    return (
      <div className="d-flex flex-column justify-content-between mb-7">
        {title && <h2 className="fs-72 txt-gray-900 font-opensans-light ms-5 mt-5 title-btn">{title}</h2>}
        {content && (
          <span className="fs-20 txt-gray-900 fontSFPro-regular ms-5 my-5 w-75">
            <RichTextRenderer richText={content} />
          </span>
        )}
        {ctaButton && (
          <Link
            className="d-flex align-items-center justify-content-center fs-18 btn-primary fontSFPro-bold checkout-btn ms-5"
            to={ctaButton.slug}
          >
            {ctaButton.title}
          </Link>
        )}
      </div>
    )
  }
  return (
    <>
      {isEligibleAmbassador === true ? (
        blocks.map((block, index) => {
          if (block.__typename === 'ContentfulImageWithOverlayText')
            return <ContenfulImageOverlayText key={index} order={index} data={block} />
          if (block.__typename === 'ContentfulTextBlock') return <TextBlock key={index} data={block} />
        })
      ) : (
        <Row className="py-7">
          <Col>
            <Alert variant="success" className="p7">
              <h3>
                Thanks you for order
                <Link to="/shop" className="ms-5 c-pointer text-blue-light">
                  Continue shopping
                </Link>
              </h3>
            </Alert>
          </Col>
        </Row>
      )}
    </>
  )
}

export default CheckoutThankYou
