import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { CreditCardInput, SquarePaymentsForm } from 'react-square-web-payments-sdk'
import { useCart, useSquareCheckout } from '@chordcommerce/gatsby-theme-autonomy'
import { CHECKOUT_STEPS } from '~/components/Cart/Checkout/CheckoutPage'
import { useContentfulCustomer } from '~/hooks/contenful-customer'
import ChordService from '~/api/chord-services'
import useAmbassadorTermsQuery from '~/hooks/graphql/queries/use-ambassador-terms'

const CheckoutPayment = ({ activeStep, appUser, isEligibleAmbassador, handleComplete }) => {
  const chordSquare = useSquareCheckout()
  const chordCommerce = useCart()
  const { contentfulCustomer } = useContentfulCustomer()
  const [paymentDetails, setPaymentDetails] = useState(null)
  const ambassadorAgreementText = useAmbassadorTermsQuery()

  useEffect(() => {
    return () => {
      setPaymentDetails(null)
    }
  }, [])

  useEffect(() => {
    if (activeStep === CHECKOUT_STEPS.PAYMENT && chordCommerce.cart.data.billAddress) {
      const newPaymentData = {
        amount: chordCommerce.cart.data.displayTotal,
        address: [chordCommerce.cart.data.billAddress.address1, chordCommerce.cart.data.billAddress.address2],
        lastName: appUser.name.split(' ')[0],
        firstName: appUser.name.split(' ')[1],
        countryCode: 'US',
        city: chordCommerce.cart.data.billAddress.city
      }
      setPaymentDetails(newPaymentData)
    }
    return () => {}
  }, [activeStep, chordCommerce.cart.data.billAddress])

  async function updateOrder(squareToken) {
    const paymentAttributes = {
      payment_method_type: 'square',
      source_attributes: {
        nonce: squareToken
      }
    }
    const orderNumber = chordCommerce.cart.data.number

    await chordSquare.updateOrderPayment(paymentAttributes)
    const finalizedCart = await chordSquare.finalizeCheckout({ orderNumber })
    if (isEligibleAmbassador && Object.keys(finalizedCart).length > 0) {
      await ChordService.updateAmbassadorRole(finalizedCart.userId)
      const user = await contentfulCustomer.find(finalizedCart.email)
      if (user === null) {
        await contentfulCustomer.create(
          finalizedCart.userId,
          finalizedCart.email,
          appUser.name,
          ambassadorAgreementText.content.raw
        )
      }
    }
    handleComplete(true)
  }

  return (
    <Row className="border-top border-gray-200 mt-3 pt-3">
      <Col className="col-auto">
        <span className="circle-number border border-primary rounded-circle">
          {activeStep === CHECKOUT_STEPS.PAYMENT ? <FaCheck /> : '3'}
        </span>
      </Col>
      <Col className="col d-flex flex-column">
        <h3 className="fs-22">Payment</h3>

        {paymentDetails !== null && (
          <Col className="mt-3 mb-5">
            <SquarePaymentsForm
              applicationId={process.env.GATSBY_SQUARE_APPLICATION_ID}
              /**
               * Invoked when payment form receives the result of a tokenize generation request.
               * The result will be a valid credit card or wallet token, or an error.
               */
              cardTokenizeResponseReceived={(token, buyer) => {
                updateOrder(token?.token)
              }}
              createVerificationDetails={() => ({
                amount: paymentDetails.amount,
                billingContact: {
                  addressLines: paymentDetails.address,
                  familyName: paymentDetails.lastName,
                  givenName: paymentDetails.firstName,
                  countryCode: 'US',
                  city: paymentDetails.city
                },
                currencyCode: 'USD',
                intent: 'CHARGE'
              })}
              locationId={process.env.GATSBY_SQUARE_LOCATION_ID}
            >
              <CreditCardInput />
            </SquarePaymentsForm>
          </Col>
        )}
      </Col>
    </Row>
  )
}

export default CheckoutPayment
