import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'gatsby'

const CheckoutNoItems = () => {
  return (
    <Row className="py-7">
      <Col>
        <h3>
          No items in cart, please check our products
          <Link to="/shop" className="ms-5 c-pointer text-blue-light">
            Shop
          </Link>
        </h3>
      </Col>
    </Row>
  )
}

export default CheckoutNoItems
