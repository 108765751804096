import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SummaryOrderItem from '~/components/Cart/Checkout/SummaryOrderItem'
import { getPromotions, getSubscriptionsDiscount } from '~/utils/cart'
import CartPromoCode from '~/components/Cart/CartPromoCode'

const SummaryOrder = ({ cartItems, displayTotal, displayItemTotal, displayShipTotal, displayTaxTotal }) => {
  const [subscriptionDiscounts, setSubscriptionDiscounts] = useState(0)
  const [cartPromotions, setCartPromotions] = useState([])

  useEffect(() => {
    setSubscriptionDiscounts(getSubscriptionsDiscount(cartItems))
    setCartPromotions(getPromotions(cartItems))

    return () => {}
  }, [])

  return (
    <>
      {cartItems.map(cartItem => {
        return (
          <SummaryOrderItem
            id={cartItem.id}
            key={cartItem.id}
            product={cartItem.variant}
            amount={cartItem.displayAmount}
            quantity={cartItem.quantity}
            subscriptionLineItems={cartItem.subscriptionLineItems}
          />
        )
      })}
      <div className="row">
        <div className="col-10 text-end pe-4">Subtotal:</div>
        <div className="col-2">{displayItemTotal}</div>
      </div>
      {subscriptionDiscounts > 0 && (
        <div className="row">
          <div className="col-10 text-end pe-4">Subscription discounts:</div>
          <div className="col-2">-${subscriptionDiscounts}</div>
        </div>
      )}
      {cartPromotions.map(promotion => {
        return (
          <div className="row text-success" key={promotion.id}>
            <div className="col-10 text-end pe-4">{promotion.label}:</div>
            <div className="col-2">{promotion.displayAmount}</div>
          </div>
        )
      })}
      <div className="row mt-3">
        <div className="col-12">
          <CartPromoCode />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-10 text-end pe-4">Shipping:</div>
        <div className="col-2">{displayShipTotal}</div>
      </div>
      <div className="row">
        <div className="col-10 text-end pe-4">Taxes:</div>
        <div className="col-2">{displayTaxTotal}</div>
      </div>
      <div className="row mt-4 fs-22 fw-bold">
        <div className="col-10 text-end pe-4">Order total:</div>
        <div className="col-2">{displayTotal}</div>
      </div>
    </>
  )
}

SummaryOrder.propTypes = {
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      variant: PropTypes.shape({
        id: PropTypes.number.isRequired,
        sku: PropTypes.string.isRequired,
        description: PropTypes.string,
        images: PropTypes.array
      }).isRequired
    })
  ),
  displayTotal: PropTypes.string.isRequired,
  displayItemTotal: PropTypes.string.isRequired,
  displayShipTotal: PropTypes.string.isRequired,
  displayTaxTotal: PropTypes.string.isRequired
}

export default SummaryOrder
