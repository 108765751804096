import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getProductSubscription } from '~/utils/cart'

const SummaryOrderItem = ({ id, product, amount, quantity, subscriptionLineItems }) => {
  const [subscription, setSubscription] = useState(null)

  useEffect(() => {
    //Since the cartItem can have multiple subscriptionLineItems, search by product sku
    if (subscriptionLineItems.length > 0) {
      setSubscription(getProductSubscription(product.sku, subscriptionLineItems))
    }

    return () => {
      setSubscription(null)
    }
  }, [subscriptionLineItems, product.sku])

  return (
    <div className="row mt-2 border-bottom border-gray-200 mb-3 pb-3">
      <div className="col-10 d-flex flex-row">
        {product.images.length > 0 && (
          <div className="prod-img me-2" style={{ width: '77px' }}>
            <img src={product.images[0].smallUrl} alt={product.name} className="img-fluid" />
          </div>
        )}
        <div className="prod-info d-flex flex-column justify-content-between">
          <div className="d-flex flex-column">
            <h3 className="fw-bold fs-16 m-0 mb-1">{product.name}</h3>
            <span className="fs-14">
              {subscription === null ? (
                <>Qty: {quantity}</>
              ) : (
                <>
                  <strong>SUBSCRIPTION:</strong>
                  <br /> renews every {subscription.interval}
                </>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="col-2">{amount}</div>
    </div>
  )
}

SummaryOrderItem.propTypes = {
  id: PropTypes.number.isRequired,
  amount: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  subscriptionLineItems: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired
    })
  ),
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sku: PropTypes.string.isRequired,
    description: PropTypes.string,
    images: PropTypes.array
  }).isRequired
}
export default SummaryOrderItem
