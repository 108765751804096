import Metadata from '~/components/Metadata'
import React from 'react'
import Layout from '~/components/Layout'
import CheckoutPage from '~/components/Cart/Checkout/CheckoutPage'

const Checkout = () => {
  return (
    <Layout>
      <Metadata title="Checkout Page" />
      <CheckoutPage />
    </Layout>
  )
}

export default Checkout
