import React, { useEffect, useState } from 'react'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import SummaryOrder from '~/components/Cart/Checkout/SummaryOrder'
import CheckoutShipping from '~/components/Cart/Checkout/CheckoutShipping'
import CheckoutUserInfo from '~/components/Cart/Checkout/CheckoutUserInfo'
import CheckoutPayment from '~/components/Cart/Checkout/CheckoutPayment'
import Loader from '~/components/Loader'
import CheckoutNoItems from '~/components/Cart/Checkout/CheckoutNoItems'
import CheckoutThankYou from '~/components/Cart/Checkout/CheckoutThankYou'
import ChordServices from '~/api/chord-services'
import { useAuth } from '~/context/auth-context'
export const CHECKOUT_STEPS = {
  USER: 'user',
  SHIPPING: 'shipping',
  PAYMENT: 'payment'
}

const CheckoutPage = () => {
  const chordCommerce = useCart()
  const { user } = useAuth()
  const [checkoutStep, setCheckoutStep] = useState(CHECKOUT_STEPS.USER)
  const [isEligibleAmbassador, setIsEligibleAmbassador] = useState(false)
  const [appUser, setAppUser] = useState(null)
  const [cartData, setCartData] = useState(null)
  const [showThankYou, setShowThankYou] = useState(false)

  useEffect(() => {
    setAppUser(user)
    return () => {
      setCartData(null)
      setAppUser(null)
      setIsEligibleAmbassador(false)
    }
  }, [])

  useEffect(() => {
    if (!chordCommerce.cart.isFetching) {
      if (Array.isArray(chordCommerce.cart.data.lineItems) && chordCommerce.cart.data.lineItems.length > 0) {
        // Check if the customer is eligible to become an ambassador
        chordCommerce.cart.data.lineItems.map(lineItem => {
          ChordServices.isProductKit(lineItem.variant.productId).then(result => {
            if (result && user) {
              setIsEligibleAmbassador(true)
            }
          })
        })

        setCartData(chordCommerce.cart.data)
      } else {
        setCartData(null)
      }
    }

    return () => {}
  }, [chordCommerce.cart])

  const updateAppUser = data => {
    setAppUser({ ...appUser, ...data })
  }

  if (chordCommerce.cart.isFetching) {
    return <Loader />
  }

  if (showThankYou) {
    return <CheckoutThankYou isEligibleAmbassador={isEligibleAmbassador} />
  }

  if (cartData === null) {
    return <CheckoutNoItems />
  }

  return (
    <div className="font-sfpro py-5">
      <div className="row">
        <div className="col-12">
          <h1 className="font-opensans-light  mb-0 fs-60">{isEligibleAmbassador ? 'Join Our Team' : 'Checkout'}</h1>
        </div>
      </div>
      <div className="row fs-22">
        <div className="col-md-8">Message area</div>
        <div className="col-md-4 fw-bold">Order Summary:</div>
      </div>
      <div className="row border-top border-gray-200 mt-4 pt-4">
        <div className="col-md-6">
          <CheckoutUserInfo
            appUser={appUser}
            handleAppUser={updateAppUser}
            handleStep={setCheckoutStep}
            activeStep={checkoutStep}
            isEligibleAmbassador={isEligibleAmbassador}
          />
          <CheckoutShipping appUser={appUser} handleStep={setCheckoutStep} activeStep={checkoutStep} />
          <CheckoutPayment
            activeStep={checkoutStep}
            appUser={appUser}
            isEligibleAmbassador={isEligibleAmbassador}
            handleComplete={setShowThankYou}
          />
        </div>
        <div className="col-md-4 offset-md-2">
          {Array.isArray(chordCommerce.cart.data.lineItems) && chordCommerce.cart.data.lineItems.length > 0 && (
            <SummaryOrder
              cartItems={chordCommerce.cart.data.lineItems}
              displayTotal={chordCommerce.cart.data.displayTotal}
              displayItemTotal={chordCommerce.cart.data.displayItemTotal}
              displayShipTotal={chordCommerce.cart.data.displayShipTotal}
              displayTaxTotal={chordCommerce.cart.data.displayTaxTotal}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CheckoutPage
