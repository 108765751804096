import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FaCheck } from 'react-icons/fa'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Link } from 'gatsby'
import { useForm } from 'react-hook-form'
import FormError from '~/components/FormError'
import { CHECKOUT_STEPS } from '~/components/Cart/Checkout/CheckoutPage'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import CheckoutTerms from '~/components/Cart/Checkout/CheckoutTerms'
import ChordServices from '~/api/chord-services'
import AmbassadorTypeahead from '~/components/AmbassadorTypeahead'
import { useApp } from '~/context/app-context'

const CheckoutUserInfo = ({ appUser, handleAppUser, handleStep, activeStep, isEligibleAmbassador }) => {
  const chordCommerce = useCart()
  const { affiliate } = useApp()
  const [relatedAmbassador, setRelatedAmbassador] = useState(null)
  const [showUserForm, setShowUserForm] = useState(true)
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors }
  } = useForm({
    defaultValues: {
      userFullName: '',
      userEmail: ''
    }
  })

  useEffect(() => {
    if (affiliate) {
      setRelatedAmbassador(affiliate)
    }

    return () => {
      setRelatedAmbassador(null)
      setShowUserForm(true)
    }
  }, [])

  useEffect(() => {
    if (appUser !== null) {
      setValue('userFullName', appUser.name)
      setValue('userEmail', appUser.email)
    }
    return () => {}
  }, [appUser])

  useEffect(() => {
    if (!chordCommerce.cart.isFetching && chordCommerce.cart.data.email !== undefined) {
      setValue('userEmail', chordCommerce.cart.data.email)
    }
    return () => {}
  }, [chordCommerce.cart.data.email])

  const clickAgreeTerms = agree => {
    if (agree) {
      setValue('acceptTerms', true)
      trigger()
    }
  }

  async function onSubmit(data) {
    const userData = {
      name: data.userFullName,
      email: data.userEmail,
      relatedAmbassador: null
    }

    await chordCommerce.modifyCart({ email: userData.email })
    if (relatedAmbassador !== null) {
      await ChordServices.setAmbassadorOnOrder(chordCommerce.cart.data.number, relatedAmbassador.chordUserId)
      userData.relatedAmbassador = relatedAmbassador
    }

    handleAppUser(userData)
    handleStep(CHECKOUT_STEPS.SHIPPING)
    setShowUserForm(false)
  }

  const editUser = () => {
    setShowUserForm(true)
    handleStep(CHECKOUT_STEPS.USER)
  }

  const onSelectAmbassador = data => {
    setRelatedAmbassador(data)
  }

  return (
    <div className="row">
      <div className="col-auto">
        <span className="circle-number border border-primary rounded-circle">
          {appUser !== null ? <FaCheck /> : <>1</>}
        </span>
      </div>
      <div className="col d-flex flex-column">
        <h3 className="fs-22">
          My Information
          {appUser !== null && (
            <a onClick={() => editUser()} className="fs-16 txt-blue-light ms-2 c-pointer">
              Edit
            </a>
          )}
        </h3>
        {appUser === null && (
          <div>
            Returning customer?
            <span className="txt-blue-light ms-2">Use my saved information ›</span>
          </div>
        )}

        <div className={`mt-3 mb-5`}>
          {activeStep !== CHECKOUT_STEPS.USER && appUser !== null && (
            <Col>
              <p className="m-0">
                <strong>Name:</strong>
                {appUser.name}
              </p>
              <p className="m-0">
                <strong>Email:</strong>
                {appUser.email}
              </p>
              {appUser.relatedAmbassador && (
                <p className="m-0">
                  <strong>Ambassador:</strong>
                  {appUser.relatedAmbassador.name}
                </p>
              )}
            </Col>
          )}
          <Col className={activeStep === CHECKOUT_STEPS.USER && showUserForm ? 'd-block' : 'd-none'}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="userFullName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  {...register('userFullName', { required: 'Full name is required' })}
                  className={errors.userFullName ? 'border-danger' : ''}
                  type="text"
                  placeholder="Full Name"
                />
                <FormError error={errors.userFullName} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="userEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  {...register('userEmail', {
                    required: 'Email is required',
                    pattern: {
                      value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: 'Invalid email address'
                    }
                  })}
                  className={errors.userEmail ? 'border-danger' : ''}
                  type="text"
                  placeholder="Email"
                />
                <FormError error={errors.userEmail} />
              </Form.Group>

              <div className="mt-5 mb-4">Shopping with a Commons Ambassador?</div>

              <Form.Group className="mb-3" controlId="ambassadorName">
                <Form.Label>Ambassador name</Form.Label>
                <AmbassadorTypeahead handleSelected={onSelectAmbassador} selectedAmbassador={relatedAmbassador} />
              </Form.Group>

              {isEligibleAmbassador && (
                <CheckoutTerms handleAgree={clickAgreeTerms} register={register} errors={errors} />
              )}

              <Row className="d-flex align-items-center">
                <Col>
                  <Link to="/shop" className="txt-blue-light">
                    ‹ Back to Shopping Bag
                  </Link>
                </Col>
                <Col>
                  <Button variant="btn btn-lg w-100 py-3 btn-primary" type="submit">
                    NEXT: SHIPPING >
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </div>
      </div>
    </div>
  )
}

CheckoutUserInfo.propTypes = {
  handleStep: PropTypes.func,
  activeStep: PropTypes.string.isRequired,
  eligibleAmbassador: PropTypes.bool
}

export default CheckoutUserInfo
