import React, { useEffect, useState } from 'react'
import { FormText } from 'react-bootstrap'

const FormError = errorData => {
  const [errMessage, setErrMessage] = useState('')
  useEffect(() => {
    let msg = ''
    if (errorData !== undefined && errorData.error !== undefined) {
      msg = errorData.error.message
      if (msg === '') {
        switch (errorData.error.type) {
          case 'required':
            msg = 'Field required'
            break
          case 'pattern':
            msg = 'Patter si not valid'
            break
        }
      }
    }
    setErrMessage(msg)
    return () => {
      msg = ''
      setErrMessage(null)
    }
  }, [errorData])

  if (errMessage === '') {
    return ''
  }

  return <FormText className="text-danger">{errMessage}</FormText>
}

export default FormError
