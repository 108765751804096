import React from 'react'
import { Spinner } from 'theme-ui'

const Loader = ({ size = '' }) => {
  return (
    <div className="container">
      <div className={`row ${size === 'sm' ? 'py-1' : 'py-5'}`}>
        <div className={`col-12 text-center ${size === 'sm' ? 'py-1' : 'py-5'}`}>
          <Spinner size={size === 'sm' ? 20 : 50} />
        </div>
      </div>
    </div>
  )
}

export default Loader
