import React, { useEffect, useState } from 'react'
import { Alert, Button, FormControl, FormLabel } from 'react-bootstrap'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import Loader from '~/components/Loader'

const CartPromoCode = () => {
  const chordCommerce = useCart()
  const [promoCode, setPromoCode] = useState(null)
  const [promoCodeError, setPromoCodeError] = useState(null)
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    return () => {
      setPromoCode(null)
      setPromoCodeError(null)
    }
  }, [])

  const savePromoCode = async () => {
    if (promoCode !== null) {
      try {
        await chordCommerce.addPromoCode(promoCode)
      } catch (err) {
        setPromoCodeError(err.message)
        setShowAlert(true)
      }
    }
  }

  if (chordCommerce.cart.promoCode.isFetching) {
    return <Loader size="sm" />
  }
  return (
    <div className="d-flex flex-column">
      <FormLabel htmlFor="promoCode" className="mb-0">
        Do you have a promo code
      </FormLabel>
      <div className="d-flex flex-row">
        <FormControl
          id="promoCode"
          type="text"
          onChange={e => {
            setPromoCode(e.target.value)
          }}
          size="sm"
          className="me-2 py-1"
        />
        <Button onClick={() => savePromoCode()} variant="primary" size="sm">
          SUBMIT
        </Button>
      </div>
      {promoCodeError !== null && showAlert && (
        <Alert onClose={() => setShowAlert(false)} dismissible variant="danger fs-14 p-2 mt-1">
          {promoCodeError}
        </Alert>
      )}
    </div>
  )
}

export default CartPromoCode
