import { graphql, useStaticQuery } from 'gatsby'

const useAmbassadorTermsQuery = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulTextBlock(identifier: { eq: "ambassador-agreement-terms" }) {
        title
        content {
          raw
        }
      }
    }
  `)

  return data.contentfulTextBlock
}

export default useAmbassadorTermsQuery
