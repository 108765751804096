import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, Row } from 'react-bootstrap'

import { Link } from 'gatsby'
import { useForm } from 'react-hook-form'
import { CHECKOUT_STEPS } from '~/components/Cart/Checkout/CheckoutPage'
import FormError from '~/components/FormError'
import { useCart, useSquareCheckout } from '@chordcommerce/gatsby-theme-autonomy'
import AutocompletePlaces from 'react-google-autocomplete'
import { FaCheck } from 'react-icons/fa'

const ShippingDetails = ({ shipAddress }) => {
  return (
    <>
      <p className="m-0">
        <strong>Name:</strong>
        {shipAddress.name}
      </p>
      <p className="m-0">
        <strong>Address:</strong>
        {shipAddress.address1 + ',' + shipAddress.address2}
      </p>
      <p className="m-0">
        <strong>City:</strong>
        {shipAddress.city}
      </p>
      <p className="m-0">
        <strong>State:</strong>
        {shipAddress.stateName}
      </p>
      <p className="m-0">
        <strong>Zipcode:</strong>
        {shipAddress.zipcode}
      </p>
      <p className="m-0">
        <strong>Phone:</strong>
        {shipAddress.phone}
      </p>
    </>
  )
}

const CheckoutShipping = ({ appUser, handleStep, activeStep }) => {
  const chordCommerce = useCart()
  const chordSquare = useSquareCheckout()
  const [shippingStates, setShippingStates] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [userShipAddress, setUserShipAddress] = useState(null)
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      shipping: {
        name: '',
        address1: '',
        address2: '',
        city: '',
        stateText: '',
        zipcode: '',
        phone: ''
      }
    }
  })

  useEffect(() => {
    if (activeStep === CHECKOUT_STEPS.SHIPPING) {
      setShowForm(true)
    } else {
      setShowForm(false)
    }
    return () => {}
  }, [activeStep])

  useEffect(() => {
    chordSquare.getStates('US').then(r => {
      setShippingStates(r.states)
    })

    if (appUser?.shipAddress) {
      setUserShipAddress(createShipAddress(appUser.shipAddress))
    }

    return () => {
      setShippingStates([])
      setUserShipAddress(null)
    }
  }, [])

  useEffect(() => {
    if (chordCommerce.cart.data.shipAddress) {
      setUserShipAddress(createShipAddress(chordCommerce.cart.data.shipAddress))
    }

    return () => {}
  }, [chordCommerce.cart.data.shipAddress])

  useEffect(() => {
    if (userShipAddress !== null && shippingStates.length > 0) {
      Object.entries(userShipAddress).forEach(([key, value]) => {
        setValue('shipping.' + key, value)
      })
    }
  }, [userShipAddress, shippingStates])

  const createShipAddress = dataAddress => {
    return {
      name: dataAddress.name,
      address1: dataAddress.address1,
      address2: dataAddress.address2,
      city: dataAddress.city,
      stateText: dataAddress.state.abbr,
      stateName: dataAddress.state.name,
      zipcode: dataAddress.zipcode,
      phone: dataAddress.phone
    }
  }

  const onSubmit = data => {
    //Double check that the state exist
    const selectedShippingState = shippingStates.find(item => item.abbr === data.shipping.stateText)
    if (selectedShippingState === undefined) {
      setError('shipping.stateText', { type: 'not found', message: 'State not found' }, { shouldFocus: true })
      return
    }

    const shippingFormData = {
      name: data.shipping.name,
      address1: data.shipping.address1,
      address2: data.shipping.address2,
      city: data.shipping.city,
      country_id: selectedShippingState.countryId,
      state_id: selectedShippingState.id,
      zipcode: data.shipping.zipcode,
      phone: data.shipping.phone
    }

    async function updateAddress() {
      await chordSquare.updateOrderAddresses(shippingFormData)
      /* At the moment multiple delivery options are not supported, so this function
         is only needed to move the checkout from the delivery step to the payment step.
         During this process, shipping costs and taxes will be added to the order
       */
      await chordSquare.updateOrderDelivery()
      await chordCommerce.loadCart()

      setShowForm(false)
      handleStep(CHECKOUT_STEPS.PAYMENT)
    }

    updateAddress()
  }

  const onSelectPlace = place => {
    const { address_components, formatted_address } = { ...place }
    if (address_components !== null && address_components.length > 0) {
      address_components.map(addressItem => {
        if (addressItem.types.find(type => type === 'locality')) {
          setValue('shipping.city', addressItem.long_name, { shouldValidate: true })
        }
        if (addressItem.types.find(type => type === 'administrative_area_level_1')) {
          setValue('shipping.stateText', addressItem.short_name, { shouldValidate: true })
        }
        if (addressItem.types.find(type => type === 'postal_code')) {
          setValue('shipping.zipcode', addressItem.long_name, { shouldValidate: true })
        }
      })
    }
    if (formatted_address !== null) {
      const streetAddress = formatted_address.split(',').length > 0 && formatted_address.split(',')[0]
      setValue('shipping.address1', streetAddress, { shouldValidate: true })
    }
  }

  const onClickEdit = () => {
    setShowForm(true)
    handleStep(CHECKOUT_STEPS.SHIPPING)
  }

  return (
    <Col className="row border-top border-gray-200 mt-3 pt-3">
      <div className="col-auto">
        <span className="circle-number border border-primary rounded-circle">
          {userShipAddress !== null ? <FaCheck /> : '2'}
        </span>
      </div>
      <Row className="col d-flex flex-column">
        <h3 className="fs-22">
          Shipping Address
          {userShipAddress !== null && (
            <a onClick={() => onClickEdit()} className="fs-16 txt-blue-light ms-2 c-pointer">
              Edit
            </a>
          )}
        </h3>
        {!showForm && userShipAddress !== null && <ShippingDetails shipAddress={userShipAddress} />}

        <Col className={`mt-3 mb-5 ${showForm ? 'd-block' : 'd-none'}`}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="shippingName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control {...register('shipping.name', { required: 'Full name is required' })} type="text" />
              <FormError error={errors && errors.shipping ? errors.shipping.name : undefined} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="shippingAddress1">
              <Form.Label>Street Address</Form.Label>
              <AutocompletePlaces
                className="form-control"
                placeholder="Search..."
                apiKey={process.env.GATSBY_GOOGLE_PLACES_API_KEY}
                onPlaceSelected={place => onSelectPlace(place)}
                options={{
                  types: ['address'],
                  componentRestrictions: { country: 'us' }
                }}
                {...register('shipping.address1', { required: 'Street Address is required' })}
              />
              <FormError error={errors && errors.shipping ? errors.shipping.address1 : undefined} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="shippingAddress2">
              <Form.Label>Suite, Apt#</Form.Label>
              <Form.Control {...register('shipping.address2')} type="text" />
            </Form.Group>

            <Row className="mb-3">
              <Col>
                <Form.Group controlId="shippingCity">
                  <Form.Label>City</Form.Label>
                  <Form.Control {...register('shipping.city', { required: 'City is required' })} type="text" />
                  <FormError error={errors && errors.shipping ? errors.shipping.city : undefined} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="shippingStateText">
                  <Form.Label>State</Form.Label>
                  <Form.Select
                    aria-label="Shipping State"
                    {...register('shipping.stateText', { required: 'State is required' })}
                  >
                    <option value="">-- Choose --</option>
                    {shippingStates.map(shippingState => {
                      return (
                        <option key={shippingState.id} value={shippingState.abbr}>
                          {shippingState.name}
                        </option>
                      )
                    })}
                  </Form.Select>
                  <FormError error={errors && errors.shipping ? errors.shipping.stateText : undefined} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="shippingZipcode">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control {...register('shipping.zipcode', { required: 'Zip Code is required' })} type="text" />
                  <FormError error={errors && errors.shipping ? errors.shipping.zipcode : undefined} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="shippingPhone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control {...register('shipping.phone', { required: 'Phone Number is required' })} type="text" />
                  <FormError error={errors && errors.shipping ? errors.shipping.phone : undefined} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="d-flex align-items-center mt-4">
              <Col>
                <Link to="/cart" className="txt-blue-light">
                  ‹ Back to Shopping Bag
                </Link>
              </Col>
              <Col>
                <Button variant="btn btn-lg w-100 py-3 btn-primary" type="submit">
                  NEXT: PAYMENT >
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Col>
  )
}
CheckoutShipping.propTypes = {
  handleStep: PropTypes.func,
  activeStep: PropTypes.string.isRequired
}
export default CheckoutShipping
