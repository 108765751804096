import React, { useEffect, useState } from 'react'
import { ClearButton, Typeahead } from 'react-bootstrap-typeahead'
import { useContentfulCustomer } from '~/hooks/contenful-customer'
import 'react-bootstrap-typeahead/css/Typeahead.css'

const AmbassadorTypeahead = ({ handleSelected, selectedAmbassador }) => {
  const { contentfulCustomer } = useContentfulCustomer()
  const [ambassadors, setAmbassadors] = useState([])
  const [selectedData, setSelectedData] = useState([])

  useEffect(() => {
    async function search() {
      const results = await contentfulCustomer.loadAmbassadors()
      setAmbassadors(results)
    }
    search()

    return () => {
      setAmbassadors([])
      setSelectedData([])
    }
  }, [])

  useEffect(() => {
    //Since the selected is not multiple we can choose first item
    const ambassador = selectedData.length > 0 ? selectedData[0] : null
    handleSelected(ambassador)

    return () => {}
  }, [selectedData])

  return (
    //TODO refactor to async
    //https://ericgio.github.io/react-bootstrap-typeahead/#asynchronous-searching
    <>
      {selectedAmbassador !== null && <div>{selectedAmbassador.name}</div>}
      <Typeahead
        id="ambassadors-search"
        labelKey="name"
        onChange={setSelectedData}
        options={ambassadors}
        placeholder="Choose an ambassador..."
        selected={selectedData}
      >
        {({ onClear, selected }) => (
          <div className="rbt-aux">
            {!!selected.length && <ClearButton onClick={onClear} label="email" />}
            {/*{!selected.length && <Spinner animation="grow" size="sm" />}*/}
          </div>
        )}
      </Typeahead>
    </>
  )
}

export default AmbassadorTypeahead
