import PropTypes from 'prop-types'

const windowGlobal = typeof window !== 'undefined' && window

// @deprecated
const removeItemFromCart = async (lineItemId, cart, removeFromCart) => {
  try {
    await removeFromCart(lineItemId)

    if (windowGlobal && localStorage.getItem('ContainsKit')) {
      let containsKit = JSON.parse(localStorage.getItem('ContainsKit'))

      if (containsKit && containsKit.contains) {
        if (cart) {
          const { lineItems } = { ...cart.data }
          let match = false
          if (lineItems && lineItems.length > 0) {
            lineItems.forEach(product => {
              if (product?.variant?.sku === containsKit.sku) match = true
            })
          }
          if (match)
            //when kit is removed from cart, remove cookie so it doesn't show the Terms of Agreement option on checkout
            localStorage.removeItem('ContainsKit')
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
}

const getSubscriptionsDiscount = cartItems => {
  let amount = 0
  cartItems.map(cartItem => {
    cartItem.adjustments.map(itemAdjustment => {
      //Only eligible adjustments
      if (itemAdjustment.eligible && itemAdjustment.promotionCode === null) {
        amount += Math.abs(Number(itemAdjustment.amount))
      }
      return itemAdjustment
    })
    return cartItem
  })
  return amount
}
getSubscriptionsDiscount.propTypes = {
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      adjustments: PropTypes.arrayOf(
        PropTypes.shape({
          eligible: PropTypes.bool.isRequired,
          amount: PropTypes.number
        })
      )
    })
  )
}

const getPromotions = cartItems => {
  const promotions = []
  cartItems.map(cartItem => {
    cartItem.adjustments.map(itemAdjustment => {
      //Only eligible adjustments
      if (itemAdjustment.eligible && itemAdjustment.promotionCode !== null) {
        promotions.push(itemAdjustment)
      }
      return itemAdjustment
    })
    return cartItem
  })
  return promotions
}
getPromotions.propTypes = {
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      adjustments: PropTypes.arrayOf(
        PropTypes.shape({
          eligible: PropTypes.bool.isRequired,
          promotionCode: PropTypes.shape({})
        })
      )
    })
  )
}

const getProductSubscription = (sku, subscriptionLineItems) => {
  const productSubscription = subscriptionLineItems.find(item => item.sku === sku)
  if (typeof productSubscription.id !== 'undefined') {
    return productSubscription
  }
  return null
}
getProductSubscription.propTypes = {
  sku: PropTypes.string.isRequired,
  subscriptionLineItems: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    })
  )
}

removeItemFromCart.propTypes = {
  lineItemId: PropTypes.number,
  cart: PropTypes.object
}

const getCartAdjustments = cartItems => {
  let adjustments = []
  cartItems.map(cartItem => {
    cartItem.adjustments.map(itemAdjustment => {
      //Only eligible adjustments
      if (itemAdjustment.eligible && itemAdjustment.promotionCode === null) {
        const amount = parseFloat(itemAdjustment.amount)
        const adjustment = {
          label: itemAdjustment.label,
          // displayAmount: itemAdjustment.amount,
          amount: amount
        }
        const existing = adjustments.find(item => item.label === adjustment.label)
        if (existing !== undefined) {
          existing.amount += adjustment.amount
        } else {
          adjustments.push(adjustment)
        }
      }
    })
  })
  return adjustments
}

export { removeItemFromCart, getSubscriptionsDiscount, getPromotions, getProductSubscription, getCartAdjustments }
