import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import React from 'react'

export const ContentfulRichTextOptions = () => {
  const Bold = ({ children }) => <span className="fw-bold">{children}</span>
  const Text = ({ children }) => <span className="align-center">{children}</span>

  return {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <>
            <h2>Embedded Asset</h2>
            <pre>
              <code>{JSON.stringify(node, null, 2)}</code>
            </pre>
          </>
        )
      }
    },
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    }
  }
}
