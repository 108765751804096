import React from 'react'
import { getSrc } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const ContenfulImageOverlayText = ({ order, data }) => {
  const { title, subheading, fontColor, image, buttonTitle, buttonLink } = { ...data }

  return (
    <div className="container-fluid overflow-hidden px-0 mb-2" style={{ marginTop: order > 0 ? '0px' : '100px' }}>
      <div className="row">
        <div
          className="img-fluid"
          style={{
            backgroundColor: 'white',
            backgroundImage: `url('${image && getSrc(image)}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '80vh',
            maxHeight: '90vh'
          }}
        >
          <div
            className="col-12 col-md-6"
            style={{ paddingLeft: '3.5rem', paddingTop: '3.5rem', paddingRight: '3.5rem' }}
          >
            {title && (
              <h1 className="bannerImageTitle" style={{ color: fontColor }}>
                {title}
              </h1>
            )}
            {subheading && (
              <h2 className="bannerImageSubtitle text-uppercase" style={{ color: fontColor }}>
                {subheading}
              </h2>
            )}

            {buttonTitle && buttonLink && (
              <Link className="bannerImageCTA d-flex align-items-center justify-content-center w-100" to={buttonLink}>
                {buttonTitle}
              </Link>
            )}
          </div>
          <div className="col-12 col-md-6" />
        </div>
      </div>
    </div>
  )
}

export default ContenfulImageOverlayText
